import { RSAA } from "redux-api-middleware";
import * as actionTypes from "../constants/action-types";
import axios from "axios";
import { withAuth } from "../reducers";
import { actions, toastr } from "react-redux-toastr";
import { updateScripts } from "./supplements";
import {browsCatlgStatChng} from "./auth"
import Cookies from "js-cookie";
import { setRialtoApiUrl } from "../components/utilities/utility";
import { t } from "i18next";

const client_name = localStorage.getItem("client");
const BASE_URL = setRialtoApiUrl(client_name);


// export const CREATE_SCRIPT_REQUEST = '@@jwt/CREATE_SCRIPT_REQUEST';
// export const CREATE_SCRIPT_SUCCESS = '@@jwt/CREATE_SCRIPT_SUCCESS';
// export const CREATE_SCRIPT_FAILURE = '@@jwt/CREATE_SCRIPT_FAILURE';


// export const createScript = (rialtoToken, patientId, tenantId, practitionerId, scriptName) => {
//   var params = {
//       "tenant_id": tenantId,
//       "prescribed_by_user_id": practitionerId,
//       "prescribed_to_user_id": patientId,
//       "script_name": scriptName,
//       "prescribed_to_user_type": "Patient",
//     }

//   return {
//     [RSAA]: {
//         endpoint: BASE_URL + `/rialto/api/create_script/`,
//         method: 'POST',
//         body: JSON.stringify(params),
//         headers: withAuth({ 'Content-Type': 'application/json' }),
//         types: [
//             CREATE_SCRIPT_REQUEST, CREATE_SCRIPT_SUCCESS, CREATE_SCRIPT_FAILURE
//         ]
//       }
//   }
// }



export const HAS_SCRIPT_REQUEST = "@@jwt/HAS_SCRIPT_REQUEST";
export const HAS_SCRIPT_SUCCESS = "@@jwt/HAS_SCRIPT_SUCCESS";
export const HAS_SCRIPT_FAILURE = "@@jwt/HAS_SCRIPT_FAILURE";

export const GOT_USER_CART_REQUEST = "GOT_USER_CART_REQUEST";
export const GOT_USER_CART_SUCCESS = "GOT_USER_CART_SUCCESS";
export const GOT_USER_CART_FAILURE = "GOT_USER_CART_FAILURE";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAILURE = "UPDATE_CART_FAILURE";





export function createScript(
  rialtoToken,
  patientId,
  tenantId,
  practitionerId,
  scriptName,
  userEmail,
  orderForPractitioner,
  firstName,
  lastName,
  phone,
  selectedSupplement,
  history,
  fromSupplement,
  editNewItem,
  clientAppToken,
  profileId,
  fromCatalog = false,
  fromReport = false
) {


  return function (dispatch) {
    console.log("createScript*****************")
    var params = {
      tenant_id: tenantId,
      prescribed_by_user_id: practitionerId,
      prescribed_to_user_id: orderForPractitioner ? practitionerId : patientId,
      script_name: scriptName,
      prescribed_to_user_type: orderForPractitioner ? "Practitioner" : "Patient",
      prescribed_to_user_email: userEmail,
      rialto_token: clientAppToken,
      from_catalog: fromCatalog,
      from_report: fromReport,
    };
    console.log(params)
    if (firstName || lastName) {
      Object.assign(params, { first_name: firstName, last_name: lastName });
    }
    if (phone) {
      Object.assign(params, { phone: phone });
    }
    if(typeof undefined !== profileId){
      Object.assign(params, { profile_id: profileId });

    }
    return axios
      .post(BASE_URL + `/rialto/api/create_script`, params, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_SCRIPT,
          payload: res.data.products,
          scriptId: res.data.script_id,
          product_current_page: res.data.current_page,
          orderNumber: res.data.order_number,
          tenantDiscount: res.data.discount_percent,
        });
        dispatch(updateScripts(selectedSupplement, res.data.script_id)).then(
          (res) => {
            history.push({
              pathname: "/script_config",
              fromSupplement: fromSupplement,
              editNewItem,
            });
          }
        );
      })
      .catch((error) => {
        console.log(error);
        toastr.error("error - " + error.response.data.message);
      });
  };
}

export function pureProcreateScript(
  rialtoToken,
  patientId,
  tenantId,
  practitionerId,
  scriptName,
  userEmail,
  srciptFromPractitioner,
  firstName,
  lastName,
  phone,
  selectedSupplement,
  history,
  fromSupplement,
  editNewItem,
  clientAppToken,
  profileId,
  fromCatalog = false,
  fromReport = false
) {

  console.log(srciptFromPractitioner, "srciptFromPractitioner");

  return function (dispatch) {
    var params = {
      tenant_id: tenantId,
      prescribed_by_user_id: practitionerId,
      prescribed_to_user_id: patientId,
      script_name: scriptName,
      prescribed_to_user_type: srciptFromPractitioner ? "User" : "Patient",
      prescribed_to_user_email: userEmail,
      rialto_token: clientAppToken,
      profile_id: profileId,
      from_catalog: fromCatalog,
      from_report: fromReport,
    };
    if (firstName || lastName) {
      Object.assign(params, { first_name: firstName, last_name: lastName });
    }
    if (phone) {
      Object.assign(params, { phone: phone });
    }
    return axios
      .post(BASE_URL + `/rialto/api/create_script`, params, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.PRO_CREATE_SCRIPT,
          payload: res.data.products,
          scriptId: res.data.script_id,
          product_current_page: res.data.current_page,
          orderNumber: res.data.order_number,
          tenantDiscount: res.data.discount_percent,
        });
        dispatch(updateScripts(selectedSupplement, res.data.script_id))
      })
      .catch((error) => {
        console.log(error);
        toastr.error("error - " + error.response.data.message);
      });
  };
}



export function createRecentScript(rialtoToken,
  patientId,
  tenantId,
  practitionerId,
  scriptName,
  userEmail,
  orderForPractitioner,
  firstName,
  lastName,
  phone,
  selectedSupplement,
  clientAppToken,
  profileId,
  history) {
  return function (dispatch) {
    console.log("createRecentScript*****************")
    var params = {
      tenant_id: tenantId,
      prescribed_by_user_id: practitionerId,
      prescribed_to_user_id: orderForPractitioner ? practitionerId : patientId,
      script_name: scriptName,
      prescribed_to_user_type: orderForPractitioner
        ? "Practitioner"
        : "Patient",
      prescribed_to_user_email: userEmail,
      rialto_token: clientAppToken,
      profile_id: profileId,
      is_recent_script: true

    };
    console.log(params)

    if (firstName || lastName) {
      Object.assign(params, { first_name: firstName, last_name: lastName });
    }
    if (phone) {
      Object.assign(params, { phone: phone });
    }
    if (profileId) {
      Object.assign(params, { profile_id: profileId });
    }
    console.log(params)
    console.log("createRecentScript*****************")

    const updateItem = (selectedSupplement, dispatch, script_id) => new Promise((resolve, reject) => {
      dispatch(updateScripts(selectedSupplement, script_id)).then(() => {
        dispatch(scriptDetailsById(rialtoToken, script_id)).then(() => {
          history.push("/supplements");
        })
      })

    })

    return axios
      .post(BASE_URL + `/rialto/api/create_script`, params, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_SCRIPT,
          payload: res.data.products,
          scriptId: res.data.script_id,
          product_current_page: res.data.current_page,
          orderNumber: res.data.order_number,
          tenantDiscount: res.data.discount_percent,
        });

        updateItem(selectedSupplement, dispatch, res.data.script_id)
      })
      .catch((error) => {
        console.log(error);
        toastr.error("error - " + error.response.data.message);
      });
  };
}

export function getDosage() {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/catalog/get_dosages`)
      .then((res) => {

        dispatch({
          type: actionTypes.GET_DOSAGE,
          dose_frequancy: res.data.dose_frequency,
          dose_other_info: res.data.dose_other_info,
          dose_qunatity: res.data.dose_qunatity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function practitionerScriptList(
  rialtoToken,  
  pageSize,
  currentPage
) {
  return function (dispatch) {

    return axios
      .get(
        BASE_URL +
        `/rialto/api/practice_scripts?per_page=${pageSize}&page=${currentPage}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({ type: actionTypes.PRACTITIONER_SCRIPTS, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function patientScriptList(
  rialtoToken,
  patientId,
  tenantId,
  clientAppToken
) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        `/rialto/api/patient_scripts?user_id=${patientId}&tenant_id=${tenantId}&rialto_token=${clientAppToken}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({ type: actionTypes.PATIENT_SCRIPTS, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function scriptDetailsById(rialtoToken, scriptId) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SCRIPT_DETAILS,
          payload: res.data,
          tenantDiscount: res.data.discount_percent,
        });
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function scriptSent(rialtoToken, scriptId, history, tenantId) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}/sent`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({ type: actionTypes.SENT_SCRIPT, payload: res.data });
        history.push({
          pathname: "/tenants/" + tenantId + "/catalog_list",
          scriptDetailBack: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function pg3scriptSent(rialtoToken, scriptId, history, tenantId, isFromPPD = false) {
  return function (dispatch) {
    let redirectTo = isFromPPD ? "/purepro_practice_order_and_scripts" : "/pg_practice_recommendations"
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}/sent`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({ type: actionTypes.SENT_SCRIPT, payload: res.data });
        history.push({ pathname: redirectTo, scriptDetailBack: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function editScript(rialtoToken, scriptId, history) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_SCRIPT,
          payload: res.data,
          scriptId: scriptId,
          scriptName: res.data.name,
          tenantDiscount: res.data.discount_percent
        });
        !res.data.profile_id && dispatch(browsCatlgStatChng())
        history.push({ pathname: "/script_config", scriptEdit: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function cartScript(rialtoToken, scriptId, history) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_SCRIPT,
          payload: res.data,
          scriptId: scriptId,
          scriptName: res.data.name,
          tenantDiscount: res.data.discount_percent,
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }
}



export function editScriptDetails(rialtoToken, scriptId, scriptName) {

  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {

        dispatch({
          type: actionTypes.EDIT_SCRIPT_DETAILS,
          payload: res.data,
          scriptId: scriptId,
          scriptName: res.data.name,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function practitionerOrderList(
  rialtoToken,
  practitionerId,
  tenantId,
  clientAppToken
) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        `/rialto/api/practice_self_orders?user_id=${practitionerId}&tenant_id=${tenantId}&rialto_token=${clientAppToken}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({ type: actionTypes.PRACTITIONER_ORDERS, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function scriptInCart(scriptId) {
  const r_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}/in_cart`, {
        headers: {
          Authorization: `Bearer ${r_client_token}`,
        },
      })
      .then((res) => {
        dispatch({ type: actionTypes.SCRIPT_INCART, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function scriptViewed(scriptId, data = {}) {
  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  const fromReportPage = data?.fromReportPage || false;
  const fromViewSuppRecomm = data?.fromViewSuppRecomm || false;

  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}/viewed?from_report=${fromReportPage}&from_supp_recomm_dashboard=${fromViewSuppRecomm}`, {
        headers: {
          Authorization: `Bearer ${rialto_client_token}`,
        },
      })
      .then((res) => {
        dispatch({ type: actionTypes.SCRIPT_VIEWED, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function scriptOrderDetails(orderId) {
  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${rialto_client_token}`,
        },
      })
      .then((res) => {
        dispatch({ type: actionTypes.SCRIPT_ORDER_DETAILS, payload: res.data });
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function checkoutScript(scriptId, orderId, history) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CHECKOUT_SCRIPT,
      scriptId: scriptId,
      orderNumber: orderId,
    });
    history.push("/shipping");
  };
}

export function checkoutScriptShipping(scriptId, orderId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CHECKOUT_SCRIPT,
      scriptId: scriptId,
      orderNumber: orderId,
    });
  };
}

export function patientOrderList(
  rialtoToken,
  patientId,
  tenantId,
  clientAppToken
) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        `/rialto/api/patient_orders?user_id=${patientId}&tenant_id=${tenantId}&rialto_token=${clientAppToken}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({ type: actionTypes.PATIENT_ORDERS, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function clearScriptDetails() {
  return function (dispatch) {
    dispatch({ type: actionTypes.CLEAR_SCRIPT_DETAIL });
  };
}


export function purePropractitionerRecommendationList(
  rialtoToken, 
  perPage,
  currentPage,
  sort,
  sort_dir) {
  return function (dispatch) {    
    dispatch({
      type: actionTypes.PRACTITIONER_RECOMMENDATION_LIST_INIT,
    });
    return axios
      .get(
        BASE_URL +
        `/rialto/api/purepro_practitioner_orders?per_page=${perPage}&page=${currentPage}&sort=${sort}&sort_dir=${sort_dir}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.PRACTITIONER_RECOMMENDATION_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.PRACTITIONER_RECOMMENDATION_LIST_FAILURE,
        });
      });
  };
}

export function purePropatientRecommendationList(
  rialtoToken, 
  perPage,
  currentPage,
  sort,
  sort_dir
) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.PATIENT_RECOMMENDATION_LIST_INIT,
    });
    return axios
      .get(
        BASE_URL +
        `/rialto/api/purepro_patient_orders?per_page=${perPage}&page=${currentPage}&sort=${sort}&sort_dir=${sort_dir}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.PATIENT_RECOMMENDATION_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.PATIENT_RECOMMENDATION_LIST_FAILURE,
        });
      });
  };
}

export function practitionerRecommendationList(
  rialtoToken, 
  perPage,
  currentPage,
  flag
) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        `/rialto/api/practice_order_and_scripts?flag=${flag}&per_page=${perPage}&page=${currentPage}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.PRACTITIONER_RECOMMENDATION_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function recommendedPracticeList(
  rialtoToken,
  perPage,
  currentPage,
  sort,
  sort_dir
) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.RECOMMENDED_PRACTITIONER_LIST_INIT,
    });
    return axios
      .get(
        BASE_URL +
        `/rialto/api/purepro_practice_order_and_scripts?per_page=${perPage}&page=${currentPage}&sort=${sort}&sort_dir=${sort_dir}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.RECOMMENDED_PRACTITIONER_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.RECOMMENDED_PRACTITIONER_LIST_FAILURE,
        });
      });
  };
}
export function recommendedPatientList(
  rialtoToken,  
  perPage,
  currentPage,
  sort,
  sort_dir
) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.RECOMMENDED_PATIENT_LIST_INIT,
    });
    return axios
      .get(
        BASE_URL +
        `/rialto/api/purepro_patient_order_and_scripts?per_page=${perPage}&page=${currentPage}&sort=${sort}&sort_dir=${sort_dir}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.RECOMMENDED_PATIENT_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.RECOMMENDED_PATIENT_LIST_FAILURE,
        });
      });
  };
}

export function patientRecommendationList(
  rialtoToken,  
  perPage,
  currentPage,
  sort,
  sort_dir
) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        `/rialto/api/patient_order_and_scripts?per_page=${perPage}&page=${currentPage}&sort=${sort}&sort_dir=${sort_dir}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.PATIENT_RECOMMENDATION_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function storeOtherRecommendations(otherRecommendations) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_OTHER_RECOMMENDATIONS,
      otherRecommendations: otherRecommendations,
    });
  };
}

export function updateRecommendRequestState() {
  return function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_RECOM_REQ, update_script_req: true });
  };
}

export function scriptStatusChange(scriptId) {
  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}/updated`, {
        headers: {
          Authorization: `Bearer ${rialto_client_token}`,
        },
      })
      .then((res) => {
        dispatch({ type: actionTypes.SCRIPT_STATUS_CHANGE });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function scriptOrderUpdate(scriptId) {
  console.log("inside api");
  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')

  return function (dispatch) {
    return axios.get(BASE_URL + `/rialto/api/scripts/${scriptId}/script_order_update`, {
      headers: {
        Authorization: `Bearer ${rialto_client_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: actionTypes.SCRIPT_ORDER_UPDATE });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export function sendReminderMail(rialtoToken, scriptId) {

  return function (dispatch) {

    return axios
      .post(BASE_URL + `/rialto/api/send_reminder/${scriptId}`, {}, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        toastr.success(t('mail_sent_successfully'));
      })
      .catch((error) => {
        toastr.error("error - " + error.response.data.message);
      });
  };
}

export function reOrderScript(rialtoToken, scriptId, scriptName, IsNewScript) {
  let params = {
    "script_name": scriptName,
    ...(IsNewScript && { script_type: "new_script" })
  }
  return function (dispatch) {
    return axios
      .post(BASE_URL + `/rialto/api/create_reorder_script/${scriptId}`, params, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      }).then((res) => {
        dispatch({
          type: actionTypes.RE_ORDER,
          payload: res.data
        })
      })
  }
}


export const patientHasScript = (patientId, tenantId, appToken, userType, practitionerId) => {
  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/patient_has_script?tenant_id=${tenantId}&patient_id=${patientId}&profile_id=&userType=${userType}&practitioner_id=${practitionerId}&rialto_token=${appToken}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${rialto_client_token}`,
      },
      types: [HAS_SCRIPT_REQUEST, HAS_SCRIPT_SUCCESS, HAS_SCRIPT_FAILURE],
    },
  };
};

export const getUserCart = (patientId, tenantId, clientAppToken, patientEmail, userFirstName, userLastName) => {

  let params = {
    user_id: patientId,
    tenant_id: tenantId,
    rialto_token: clientAppToken,
    email: patientEmail,
    first_name: userFirstName,
    last_name: userLastName,
  }
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/get_user_cart`,
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      },
      types: [GOT_USER_CART_REQUEST, GOT_USER_CART_SUCCESS, GOT_USER_CART_FAILURE],
    },
  };
}


export const updateCart = (clientAppToken, tenantId, cartId, supplementCount, patientId) => {

  let params = {
    rialto_token: clientAppToken,
    tenant_id: tenantId,
    cart_id: cartId,
    supplement_count: supplementCount,
    user_id: patientId
  }

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/update_cart`,
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      },
      types: [UPDATE_CART_REQUEST, UPDATE_CART_SUCCESS, UPDATE_CART_FAILURE]
    }
  }
}

export const checkPatientCart = (scriptId) => {
  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')

  return function (dispatch) {
    return axios.get(BASE_URL + `/rialto/api/check_patient_incart/${scriptId}`, {
      headers: {
        Authorization: `Bearer ${rialto_client_token}`,
      },
    })
      .then((res) => {
        console.log(res, "res-------------------");
        dispatch({ type: actionTypes.CHECH_PATIENT_INCART, scriptId: res.script_id });
        return {scriptId: res.data.script_id}
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export function getRecommededScript(rialtoToken, scriptId, history) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        console.log(res, "edit script values");
        dispatch({
          type: actionTypes.RECOMMENDED_EDIT_SCRIPT,
          payload: res.data,
          scriptId: scriptId,
          scriptName: res.data.name,
          tenantDiscount: res.data.discount_percent,
        });
        history.push({ pathname: "/practice_recommend_script", recommedscriptEdit: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getPatientRecommededScript(rialtoToken, scriptId, history, fromViewSuppRecomm = false, userType = "") {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/scripts/${scriptId}`, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        console.log(res, "edit script values");
        dispatch({
          type: actionTypes.RECOMMENDED_EDIT_SCRIPT,
          payload: res.data,
          scriptId: scriptId,
          scriptName: res.data.name,
          tenantDiscount: res.data.discount_percent,
        });
        history.push({ 
          pathname: "/pg_practice_recommendation_script", 
          recommedscriptEdit: true, 
          fromViewSuppRecomm: fromViewSuppRecomm, 
          userType: userType 
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}


export function saveHcpSupplements(supplements) {
  return function (dispatch) {
    return new Promise((res, rej) => {
      dispatch({
        type: actionTypes.SAVE_HCP_SUPPLEMENTS,
        payload: supplements,
      });
      res();
    });
  };
}

export function pgPatientOrderList(
  rialtoToken,  
  perPage,
  currentPage,
  sort,
  sort_dir
) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.PATIENT_RECOMMENDATION_LIST_INIT,
    });
    return axios
      .get(
        BASE_URL +
        `/rialto/api/pg_patient_orders?per_page=${perPage}&page=${currentPage}&sort=${sort}&sort_dir=${sort_dir}`,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.PATIENT_RECOMMENDATION_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.PATIENT_RECOMMENDATION_LIST_FAILURE,
        });
      });
  };
}






